.bob-frame {
    .bob-window {
        .bob-header {
            img {
                width: 120px;
                margin-left: 0;
            }
        }
    }
}

.videoWrapper {
    width: 100%;
    max-height: calc(100vh - 60px);
    max-width: 800px;
    margin: 0 auto;
    padding-top: 50px;
}


.bob-frame .bob-window .bob-close-vis {
    top: 13px;
    right: 19px;
    font-size: 40px;
    line-height: 40px;
}

// iframe
// .bob-frame.contains-bob-iframe-1,
// .bob-frame.contains-bob-iframe-2 {
//     .bob-window {
//         .bob-header {
//             height: 50px;
//         }
//     }
// }

// bob-wizard
.bob-frame.contains-w1-bob-wizard-form1,
.bob-frame.contains-bob-iframe-1,
.bob-frame.contains-s1-bob-success {
    .bob-window {
        margin: 0 auto;

        .bob-header {
            img {
                margin: 10px 0 0 5px;
                width: 100px;
            }
        }
    }
}

.bob-frame.bob-frame.contains-s1-bob-success {
    .bob-close.bob-close-vis{
        display: none;
    }
}

@include media-breakpoint-up(sm) {
    .videoWrapper {
        width: 100%;
        max-height: 100vh;
    }
}

// iframe
// @include media-breakpoint-up(md) {

//     .bob-frame.contains-bob-iframe-1,
//     .bob-frame.contains-bob-iframe-2,
//     .bob-frame.contains-bob-iframe-3 {

//         .bob-window {
//             .bob-header {
//                 img {
//                     margin-left: -32px;
//                 }
//             }
//         }
//     }
// }

// bob-wizard
@include media-breakpoint-up(md) {

    .bob-frame.bob-frame.contains-w1-bob-wizard-form1,
    .bob-frame.contains-bob-iframe-1,
    .bob-frame.contains-s1-bob-success {
        .bob-window {
            width: 600px;
        }
    }

    .bob-frame.contains-bob-iframe-1 {
        .bob-window {
            width: 800px;
        }
    }
}

@include media-breakpoint-up(lg) {
    .videoWrapper {
        padding-top: 100px;
        margin: 100px auto;
    }
}
