.paragraph {
	margin: 30px 0;

	h3 {
		font-size: $h3-font-size*1.1;

		&:after {
			content: "";
			margin: 5px 0 15px 0;
			width: 88px;
			height: 4px;
			background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, $brand-app, $brand-sec);
			display: block;
			transition: 2ms;
			// opacity: 0;
		  }
	}

	p {
		font-weight: 400;
		font-size: $font-size-copy;
	}
}

.paragraph-img {

	.img-default {
		display: block;
		margin: 0 auto;
	}
}

// mobile first
.paragraph-img {

	.img-default {
		width: 100%;
		max-width: 150px;
		height: auto;
		margin: 0 auto 30px auto;
	}

}


@include media-breakpoint-up(md) {
	.paragraph-img {

		.img-default {
			width: 70%;
			max-width: 200px;
			height: auto;
			margin: 0 0 auto auto;
		}

	}

	.paragraph {
		margin: 40px 0;

		h3 {
			font-size: $h3-font-size*1.5;
		}
	}
}


@include media-breakpoint-up(lg) {

	.paragraph {
		margin: 60px 0;
	}

	.paragraph-img {
		display: block;

		.img-default {
			width: 46%;
		}
	}
}