// this is a custom styling for unordered lists
.jumbotron,
.passage,
.paragraph {

ul {
    padding-left: 0;
    font-size: 17px;

    li {
      list-style: none;
      padding-left: 1.25rem;
      position: relative;

      &:before {
        content: "\25CF";
        color: $brand-app;
        left: 0;
        position: absolute;
      }
    }

    span {
      font-weight: 600;
    }
  }
}

.jumbotron {
  ul {
    li {
      font-size: $font-size-hero;
    }
  }
}

@include media-breakpoint-up(md) {

  .jumbotron {
    ul {
      li {
        font-size: $font-size-hero-up-md;
      }
    }
  } 
}