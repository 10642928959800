// only applies on the science pages
body.lang-en.page_3,
body.lang-de.page_4 {
    .jumbotron {
        a {
            text-decoration: none;
            color: $brand-app;
            font-weight: 400;
            font-size: 14px;
            white-space: nowrap;
            padding: 7px 0;
            margin-bottom: 5px;

            span {
                svg {
                    width: 20px;
                    fill: $brand-app;
                }
            }

            &:hover {
                color: $brand-sec;
                span {
                    svg {
                        fill: $brand-sec;
                    }
                }
            }
        }

        .horizontal-space {
            margin: 20px 0;
        }
    }

    @include media-breakpoint-up(sm) {
        .jumbotron {
            a {
                font-size: 17px;
            }
        }
    } 

    @include media-breakpoint-up(md) {
        .jumbotron {
            a {
                display: inline-block;
            }
        }
    } 
}

.passage,
.paragraph,
.news-copy,
.publications,
.testamonial {
    a:not(.pub-more):not(.bob-btn-gradient) {
        text-decoration: none;
        border-bottom: 1px solid $brand-app;
        color: $brand-app;
    
        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
            color: $brand-sec;
            border-bottom: 1px solid $brand-sec;
        }
    }
}


