// nav active style
// based on which page is open a different nav item needs to be active
// in the german pages its special cause we have dropdowns

@mixin show-underline {
    width: 100%;
}

@mixin active-sytle-span {
    font-weight: 400;
    color: #333;
}

.nav-item.active {
    .nav-link-span {
        @include active-sytle-span;

        &:after {
            @include show-underline;
        }
    }
}

.nav-item.mobile.active {
    .nav-link {
        color: $brand-app;
    }
}
