// footer
// ******


// .footer margin-top should be 0, because of bottomizer

.footer {
	margin-top: 40px;

	p {
		font-size: 15px;
	}

	img {
		width: 60%;
	}

	.bsfz {
		max-height: 145px;
	}

	.HIPAA {
		max-height: 150px;
		max-width: 150px;
	}

	#logo-brand {
		width: initial !important;
	}


	padding-bottom: 50px;
	background: $brand-footer;

	// background: @brand-footer;

	.footer-column {
		padding-top: 50px;
		color: #fff;

		&:nth-child(n+4) {
			display: flex;
			align-items: center;
			padding-top: 0;

			img {
				width: 100%;

				&.bsfz {
					width: 197px;
					margin-left: 55px;
				}
			}
		}

		a,
		a:hover {
			text-decoration: none;
			color: #fff;
		}

		a {
			opacity: 0.7;
		}

		a:hover,
		a.active {
			opacity: 1;
		}

		ul {
			list-style: none;
			padding-left: 0;

			li {
				position: relative;
				padding-left: 13px;
			}

			.bullet {
				display: block;
				position: absolute;
				top: 11px;
				left: 0px;
				background: #fff;
				width: 6px;
				height: 6px;
				border-radius: 3px;
				opacity: 0.5;
			}

		}
	}

	.footer-logo {
		height: 100px;

		.gaia-logo {
			margin-top: 16px;
		}
	}

	.footer-legal {
		font-size: 70%;
		line-height: 1.3;
		opacity: 0.5;
		margin-bottom: 14px;
	}

	.footer-formal-links {
		margin-top: 14px;
	}
}


// show popovers (socials) if overlapping .container (desktop)
.footer .footer-links .container {
	overflow: visible;
}

.footer-redirect {
	display: none;
}


//////// footer mobile-nav

.footer {

	.footer-col-mobile {

		h3 {
			font-size: 25px;
		}

		// h3, p, .footer-img {
		// 	width: 60%;
		// 	margin: 0 auto;
		// }

		p {
			padding-top: 30px;

			// &:before {
			// 	content: "";
			// 	margin-bottom: 30px;
			// 	width: 100%;
			// 	height: 1px;
			// 	background-color: rgb(177, 177, 177);
			// 	display: block;
			// }

			a {
				opacity: 1;
			}
		}
	}
}


@include media-breakpoint-up(sm) {

	.footer {
		margin-top: 60px;

		.footer-col-mobile {
	
			h3, p, .footer-img {
				width: 100%;
				margin: 0;
			}
	
			p {
				padding-top: 10px;
	
				&:before {
					display: none;
				}
	
				a {
					opacity: 0.7;
				}
			}
		}
	}
}

@include media-breakpoint-up (md) {
	.footer {
		margin-top: 80px;

		img {
			width: 40% !important;
		}

		.footer-col-mobile {
			display: table;
			margin: 0 22% 0 auto;
		} 
	}
}

@include media-breakpoint-up(xl) {
	.footer {
		img {
			width: 100% !important;
		}

		.vfa {
			height: 97px;
			width: auto !important;
		}
	}
}

////////



@mixin link-style-on-dark ($color, $focus-bg) {

    a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.style-off) {
		color: $color;
		text-decoration: none;
        border-bottom: 1px solid $color;
        
        &:focus {
            outline: 0 none;
            background-color: $focus-bg;
        }
	}
}

.footer {
    @include  link-style-on-dark(#fff, #333)
}


