//
// Variables Diff
// --------------------------------------------------


// Brand colors
// -------------------------

$brand-app: #014b99;
$brand-sec: #02bb58;
$brand-custom: $brand-app;
$brand-hero: #fff;
$footer-bg-dark: #555555;
$brand-footer: #626262;  // this one is used
$footer-bg-light: #c0c0c0;
$link-color: $brand-app;
$brand-custom-sec: #3334;
$subtext-color: #333;

// Scaffolding
// -------------------------

$subtext-color: #808080;


// Typography

$font-family-base:        'Open Sans', sans-serif;
$font-family-goth:        'Montserrat', sans-serif;;



$font-size-base:          1.125rem; // 18px;
$font-size-lg:            ($font-size-base * 1.2);
$font-size-sm:            ($font-size-base * .86);

$font-size-hero:            16px;
$font-size-hero-up-md:      20px;
$font-size-copy:            $font-size-hero;

$h1-font-size:                $font-size-base * 1.2;
$h2-font-size:                $font-size-base * 1.1;
$h3-font-size:                $font-size-base * 1;
$h4-font-size:                $font-size-base;
$h5-font-size:                $font-size-base;
$h6-font-size:                $font-size-base * 0.86;

//** Unit-less `line-height` for use in components like buttons.
$line-height-base:        1.6; // 24/15 // 1.428571429; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
// $line-height-computed:    floor($font-size-base * $line-height-base); // ~20px

//** By default, this inherits from the `<body>`.
$headings-font-family:    $font-family-goth;
$headings-font-weight:    400; // 500;
$headings-line-height:    1.6; // 1.2;
$headings-color:          inherit;

//nav dark theme

$navbar-dark-color:                 rgba($white, .8);
$navbar-dark-hover-color:           rgba($white, 1);
$navbar-dark-active-color:          $white !default;
$navbar-dark-disabled-color:        rgba($white, .25) !default;
$navbar-dark-toggler-icon-bg:       str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23") !default;
$navbar-dark-toggler-border-color:  rgba($white, 1);
$navbar-padding-y: 3px;



//margin for spacing

$margin-vertical-mobile: 25px;
$margin-vertical-tablet: 30px;
$margin-vertical-desktop: 80px;

@mixin margin-vertical($amount){
		margin: $amount 0;
}
// -------------------------

//padding for within elements spacing

// $padding-mobile: 25px;
// $padding-tablet: 40px;
// $padding-desktop: 40px;

// @mixin inner-padding($amount, $sel:selector){

//   .#{$sel} {
//      padding: 0;
//   }

//     .#{$sel} ~ .#{$sel} {    
//       padding: $amount 0 0 0;
//     }
// }
// -------------------------


// Iconography
// -------------------------

$icon-font-path:          "fonts/";
$icon-font-name:          "glyphicons-regular"; // "glyphicons-halflings-regular";


// Components
// -------------------------
// Based on 14px font-size and 1.428 line-height (~20px to start)
//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).

$line-height-large:              1.5; // 1.3333333; // extra decimals for Win 8.1 Chrome
$line-height-small:              1.5;

// border-radius
//@border-radius-base:        4px;
$border-radius-large:       6px;
//@border-radius-small:       3px;


// jumbotron

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1040px
) !default;

@include _assert-ascending($container-max-widths, "$container-max-widths");

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12 !default;
$grid-gutter-width:           30px !default;

$grid-breakpoints: (
  xs: 0,
  phone: 480px, // as it was used in bootstrap 3 -> @screen-phone
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1480px
);

// Form 
$enable-validation-icons: false;

