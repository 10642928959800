// anchor helper
.passage-anchor {
  position: relative;
  top: -80px;
}

.passage {
  h3 {
    color: #333;
    font-weight: 400;
    font-size: 20px;

    &:after {
      content: "";
      margin: 5px 0 15px 0;
      width: 88px;
      height: 4px;
      background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, $brand-app, $brand-sec);
      display: block;
      transition: 2ms;
      // opacity: 0;
    }
  }

  p {
    font-weight: 400;
    font-size: $font-size-copy;
  }

  .passage.passage-first {
    margin: 0 0 100px 0;
  }

  .passage-txt-citation {
    font-size: 14px;
  }

}

@include media-breakpoint-up(sm) {
  .passage-anchor {
    top: -100px;
  }
}


@include media-breakpoint-up(sm) {
  .passage h3 {
    font-size: 27px;
  }
}



// this is a seperate template for including images in a passage
.passage.passage-contains-image {

  .passage-img-inner {
    width: 150px;
    margin: 30px auto;

    img {
      width: 100%;
      max-width: 200px;
    }
  }


  @include media-breakpoint-up(sm) {
    .passage-img-inner {
      float: right;
      width: 40%;
      margin: 0 0 20px 40px;
    }
  }
}