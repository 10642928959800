.news {

    .velibra {
        color : #C0A035;
        font-weight: 600;
    }

    .news-img {
        img {
            width: 80%;
            display: block;
            margin: 0 auto;
            border-radius: 6px;
        }
    }

    .newsitem {
        margin-bottom: 20px;
    }

    p {
        font-size: $font-size-copy;
    }

    .news-copy {
        margin-bottom: 50px;

        a {
            text-decoration: none;
            color: #333;

            &:hover {
                color: $brand-sec;
            }
        }

        h3 {
            font-weight: 400;
        }
    }

    .news-img,
    .news-copy {
        margin-bottom: 30px;
    }

    .newsitem-hidden {
        display: none;
    }

    .bob-btn-show-more {
        margin: 0 auto 50px auto;
        display: block;
    }
}

.news,
.news-article {

    .news-subline {
        font-size: 14px;
    }

}

.news-article {

    h1 {
        font-weight: 400;
        font-size: 28px;
        margin-bottom: 20px;
    }

    .jumbotron-gradient-span {
        margin-bottom: 15px;
    }

    .news-content-container {
        p {
            font-size: $font-size-copy;

            a.velibra {
                color : #C0A035;
                font-weight: 600;
                border-bottom: 2px solid #C0A035;
            }

            a.vorvida {
                color : #929D6D;
                font-weight: 600;
                border-bottom: 2px solid #929D6D;
            }

            a.elevida {
                color : #4DA1AE;
                font-weight: 600;
                border-bottom: 2px solid #4DA1AE;
            }

            a.levidex {
                color : #2E7B84;
                font-weight: 600;
                border-bottom: 2px solid #2E7B84;
            }

            a.priovi {
                color : #6A1A6D;
                font-weight: 600;
                border-bottom: 2px solid #6A1A6D;
            }
        }

        img {
            margin: 20px auto;
            display: block;
            width: 100%;
            max-width: 500px;
        }
    }

    .news-img {
        float: none;
        width: 100%;
        margin: 0 auto 20px auto;

        img {
            width: 100%;
        }
    }
}

@include media-breakpoint-up(sm) {
    .news {
        .news-img {
            img {
                width: 80%;
                margin: 0;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .news {

        .news-img,
        .news-copy {
            margin-bottom: 100px;
        }
    }

    .news-article {
        width: 660px;
        margin: 0 auto;

        .jumbotron-gradient-span {
            margin-bottom: 60px;
        }

        .news-img {
            float: right;
            margin: 0 0 20px 40px;
            width: 310px;
        }
    }
}