.products {

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
    width: 60%;
    margin: 0 auto 45px auto;

      a {
          &:hover {
              color: red;
              text-decoration: none;
          }
      }
  
  }

  .product {
    
    img {
      width: 106px;
    }

    p {
      font-size: $font-size-copy;
      text-decoration: underline;
    }
  }
}

@include media-breakpoint-up(sm) {
  .products {
    li {
      width: 48%;
      margin: 40px auto;
    }
  }
}


@include media-breakpoint-up(md) {
  .products {

    li {
      width: 100%;
      margin: 20px 0;
    }  
    .product {
      p {
        display: inline-block;
      }
    }
  }
}