.testamonials {
    margin-top: 20px;
    background-color: #fff;

    .testamonial {
        width: 50%;
        margin: 0 auto;
    }

     .testamonial-txt {
        p {
            margin-bottom: 5px;
        }
        a {
            white-space: nowrap;
        }
    } 

    h5 {
        text-align: center;
        font-size: 20px;
        letter-spacing: 0.2px;
        color: $brand-custom-sec;
    }

    .testamonial-content {
        padding: 15px 15px 0px 0px;
        font-size: 16px;
        font-weight: 700;
        border-radius: 5px;
        margin-bottom: 6px;

        span {
            font-weight: 300;
            font-size: 15px;
            font-family: $font-family-goth;
        }
    }

    .testamonial-content-inner {
        width: 100%;
    }

    img,
    p {
        display: inline-block;
    }

    img {
        border: solid thin #cbcbcb;
        width: 100px;
        height: 100px;
        border-radius: 50px;
        margin: 0 15px 10px 0;
    }
}

@include media-breakpoint-up(md) {
    .testamonials {
        .container {
            padding-left: 60px;
        }

        .testamonial {
            width: 33%;
        }

        h5 {
            font-size: 23px;
        }
    }
}

@include media-breakpoint-up(lg) {
    .testamonials {
        .container {
            margin: auto;

            padding-left: 0;
        }

        .testamonial {
            padding-left: 0;
            margin: 15px 0;
        }
    }
}