body.page_7 {
    .jumbotron .content h1 {
        color: gray;
        font-weight: 100;
        span {
            color: #004a90;
            font-weight: 400;
        }
    }

    .passage-gray {
        margin: 20px 0 15px 0;
        padding: 60px 0;
        background-color: #F0F0F0 !important;
    }

    .passage {
        
        h3 {
            &:after {
                content: "";
                width: 88px;
                height: 4px;
                background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, $brand-app, $brand-sec);
                display: block;
                transition: 2ms;
            }
        }

        .team {
            margin-bottom: 60px;
        
            &.team-0, &.team-1, &.team-2 {
                margin-top: 60px;
            }
            /*
            &.team-2 {
                margin-left: 23%;
            }
            */

            img {
                max-width: 311px;
                width: 100%;
                margin-bottom: 1rem;
            }

            span {
                display: block;
            }
            
            .name {
                @extend h4;
                margin-bottom: 0;
            }
        }

        &.passage-pub {
            h4 {
                margin-top: 30px;
            }
        }

        &.passage-contact {
            margin-bottom: 0;
        }
    }

    .products {
        margin-top: 60px;
        margin-bottom: 60px;


        .item {
            .logo img {
                width: 100%;
            }
            .info p {
                margin-bottom: 0;
            }
        }
    }

    .participation {

        .left {
            padding-left: 0;
            padding-right: 30px;
        }

        .right {
            margin-top: 90px;
            padding-left: 30px;
            padding-right: 0;
        }

        .item {
            padding: 0;
            margin-bottom: 60px;

            &.item-0 {
                background-color: #caf6f6;
            }

            &.item-1 {
                background-color: #d7f8f8;
            }

            &.item-2 {
                background-color: #e4fafa;
            }

            &.item-3 {
                background-color: #edffff;
            }
            
            &.item-4 {
                background-color: #f5ffff; /* RGB(245, 255, 255) */
            }
            
            &.item-5 {
                background-color: #fbffff; /* RGB(251, 255, 255) */
            }

            .data {
                display: flex;
                flex-wrap: nowrap;
                flex-direction: column;
                height: 150px;
                justify-content: center;
                padding: 30px;
            }
            .title, .date, .location {
                display: block;
            }
        }
    }

    .footer {
        margin-top: 0;
    }
}

@media only screen and (max-width: 767px) {
    body.page_7 {
        .passage {

            .team {
                &.team-2 {
                    margin-left: 0;
                }
            }
        }
    }
}

@media only screen and (max-width: 576px) {
    body.page_7 {
        .products {

            .my-sm-2 {
                margin-bottom: 0.5rem !important;
            }

            .thead {
                display: none;
            }

            .logo, .area, .phase {
                width: 33.333333%;
            }

            .logo {
                order: 1;
                img {
                    width: 100%;
                }
            }

            .area {
                order: 2;
            }

            .phase {
                order: 3
            }

            .info {
                order: 4;
            }
        }

        .participation {

            .left {
                max-width: 50%;
            }
    
            .right {
                max-width: 50%;

            }
        }

        .passage {

            .team {
                &.team-0, &.team-3 {
                    margin-top: 0;
                }
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    body.page_7 {
        .participation {

            .left {
                max-width: 100%;
                padding: 0;
            }
    
            .right {
                margin-top: 0;
                max-width: 100%;
                padding: 0;

            }
        }
    }
}