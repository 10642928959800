.passage:not(.passage-first) {
  @include margin-vertical($margin-vertical-mobile);
}

.passage-first {
  margin: 20px 0;
}

@include media-breakpoint-up(sm) {
  .passage:not(.passage-first) {
    @include margin-vertical($margin-vertical-tablet);
  }

  .passage-first {
    margin: 40px 0;
  }
}

@include media-breakpoint-up(lg) {

  .passage:not(.passage-first) {
    @include margin-vertical($margin-vertical-desktop);
  }
} 
