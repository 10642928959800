.bob-frame.contains-bob-menu {
    .nav-item {
        width: 200px;
        margin: 0 auto;
        line-height: 2;

        a {
            color: #333;
        }

        &:after {
            content: "";
            height: 2px;
            width: 100%;
            position: absolute;
            left: 0px;
            display: block;
            background-color: #9f9f9f1f;
            z-index: 1;
        }
    }

    .dropup,
    .dropright,
    .dropdown,
    .dropleft {
        position: static;
    }

    .dropdown-menu {
        display: none;
    }

    .dropdown-menu.show {
        border: none;
        margin: 0 auto;
        display: block;
        left: 0;
        width: 160px;
        transform: initial;

        .dropdown-item {
            padding: 0.25rem 0.9rem;
            line-height: 3;
        }
    }

    .bob-navbar {
        padding: 0 1rem;

        .container {
            padding: 0;
        }
    }

    .bob-header,
    .bob-close-outer {
        display: none;
    }

    .navbar-logo {
        line-height: 100px;
        background-color: #fff;
        z-index: 2;
    }

    .bob-navbar-header {
        width: 100%;
        display: block;
        height: 100px;
        display: flex;
        justify-content: space-between;
        align-content: center;
        align-items: center;
    }

    .bob-close {
        position: relative;
        z-index: 10;
        top: 0px;
        right: 0px;
    }

    .dropdown-menu.show {
        position: static;
    }

    .nav-item.dropdown {

        .nav-link {
            &:after {
                content: "-";
                position: relative;
                display: block;
                line-height: 0;
                top: -24px;
                right: -200px;
                transition: 0.4s;
                font-size: 27px;
                font-style: initial;
            }
        }

        .nav-link:not(.active) {

            &:after {
                content: "+";
            }
        }

    }

    .hide-in-nav {
        display: none;
    }


    @include media-breakpoint-up(sm) {

        .navbar {
            top: -2px;
        }
    }
}