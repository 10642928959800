body.bob-frame-open {
    padding-top: 0;
}

.form-contact {
    font-family: $font-family-goth;
}

.form-group-email {
    label {
        white-space: nowrap;
    }
}