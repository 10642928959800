.videos {
    margin: 50px 0;
}



.video {
    margin-bottom: 30px;

    h2 {
        font-size: 19px;
        font-weight: 400;
    }

    .video-img {

        width: 100%;
        display: block;
        height: 126px;
    }

    .video-btn {
        position: relative;
        padding: 0;
        width: 100%;
        max-width: 244px;
        height: 130px;
        margin: 0 auto;
        border: solid 2px transparent;
        background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, $brand-app, $brand-sec);
        box-shadow: 2px 1000px 1px #fff inset;
        cursor: pointer;
        
        &:hover,
        &:active,
	    &:focus {
            .video-btn-inner {
                &:before {
                    opacity: 1;
                }
            }
        }

        .video-btn-inner {
            // margin: 30px auto;
            position: relative;
            top: 30px;
            left: 34%;
            width: 70px;
            height: 70px;
            border-radius: 50%;
            transition: background-image 0.2s linear;
            // border: solid 2px transparent;
            background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, $brand-app, $brand-sec);
            // box-shadow: 2px 1000px 1px #fff inset;
            z-index: 1;

            &:before {
                position: absolute;
                content: "";
                top: 0;
                border-radius: 50%;
                right: 0;
                bottom: 0;
                left: 0;
                background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(90deg, $brand-sec, $brand-sec);
                z-index: -1;
                transition: opacity 0.2s linear;
                opacity: 0;
              }

            .video-btn-inner-arrow {
                position: relative;
                top: 25px;
                left: 28px;
                width: 0;
                height: 0;
                border-top: 10px solid transparent;
                border-bottom: 10px solid transparent;
                border-left: 18px solid #fff;
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    .videos {
        margin: 100px 0;
    }

    .video {
        .video-btn {
            .video-btn-inner {
                top: 34px;
                left: 33%;
                width: 60px;
                height: 60px;

                .video-btn-inner-arrow {
                    top: 21px;
                    left: 25px;
                }
            }
        }
    }
}


@include media-breakpoint-up(lg) {
    .video {
        h2 {
            font-size: 22px;
        }

        .video-btn {

            .video-btn-inner {
                top: 30px;
                left: 34%;
                width: 70px;
                height: 70px;

                .video-btn-inner-arrow {
                    top: 25px;
                    left: 28px;
                }
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .video {
        h2 {
            font-size: 24px;
        }
    }
}