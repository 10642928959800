.jumbotron {
    margin-bottom: 50px;

    .container {
        padding: 0 40px 0 35px;
    }

    .content {
        h1 {
            color: #333;
            font-weight: 400;
            font-size: 35px;
            line-height: 48px;
        }

        p {
            font-weight: 400;
            font-size: $font-size-hero;
            margin-top: 10px;
        }

        p.btn-margin {
            margin-top: 25px;
        }

        img.vfa-logo {
            width: 287px;
        }
    }
}

.jumbotron,
.news-article {
    .jumbotron-gradient-span {
        margin-top: -5px;
        width: 80px;
        max-width: 100%;
        height: 4px;
        background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, $brand-app, $brand-sec);
        display: block;
    }
}


@include media-breakpoint-up(sm) {
    .jumbotron {
        margin-bottom: 100px;

        .content {
            h1 {
                font-size: 38px;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .jumbotron {
        .container {
            padding: 0;
        }
        .content {
            h1 {
                font-size: 46px;
                line-height: 67px;
            }

            p {
                font-size: $font-size-hero-up-md;
            }
        }
    }

    .jumbotron,
    .news-article {
        .jumbotron-gradient-span {
            width: 288px;
            max-width: 100%;
        }
    }
}