.projects {
    h4 {
        font-size: 24px;
        font-weight: 400;
    }

    .projectitem {
        margin-bottom: 20px;

        p {
            font-size: $font-size-copy;
        }
    }

    .project-data {

        p {
            font-size: 17px;
        }

        .project-label {
            font-size: 12px;
        }
    }
}

.publications {

    .pubitem {
        margin: 50px 0;

        hr {
            border: 0;
            height: 0;
            border-top: 1px solid rgba(0, 0, 0, 0.2);
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        }
    }

    h4 {
        font-size: 20;
        font-weight: 400;
    }

    .pub-date,
    .pub-journal,
    .pub-link {
        font-size: 14px;
        margin-bottom: 5px;
    }

    .pub-more {
        margin-top: 10px;
    }

    .pub-abstract p {
        font-size: $font-size-copy;
        margin-top: 20px;
    }

    [data-toggle="collapse"] {
        &.collapsed .if-not-collapsed {
            display: none;
        }

        &:not(.collapsed) .if-collapsed {
            display: none;
        }
    }
}

body.lang-en.page_3,
body.lang-de.page_4 {

    .passage {
        margin: 20px 0 15px 0;
        padding: 60px 0;
        background-color: #F0F0F0;

        h3 {
            &:after {
                content: "";
                width: 88px;
                height: 4px;
                background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, $brand-app, $brand-sec);
                display: block;
                transition: 2ms;
            }
        }
    }
}


@include media-breakpoint-up(sm) {

    .projects>.projectitem,
    .publications>.pubitem {
        margin-bottom: 80px;
    }

    body.lang-en.page_3,
    body.lang-de.page_4 {
        .passage {
            margin: 100px 0 95px 0;
        }
    }
}