.navbar {
    position: fixed;
    top: 0;
    z-index: 9999;
    // height: 80px;
    width: 100%;
    background: #fff;
    line-height: 100px;
    -webkit-transition: height .5s, line-height .5s;
    /* Safari */
    transition: height .5s, line-height .5s;
    -webkit-box-shadow: 0px -14px 12px 7px rgb(0 0 0 / 36%);
    -moz-box-shadow: 0px -14px 12px 7px rgb(0 0 0 / 36%);
    box-shadow: 0px -14px 12px 7px rgb(0 0 0 / 36%);
    padding: 0 1rem;

    .container {
        overflow: visible;
    }

    .navbar-nav {
        background-color: #fff;
    }

    .dropdown-menu {
        line-height: 2;
        left: -18px;
        // top: 67px;
    }

    .nav-item-lang  {

        .dropdown-menu {
            min-width: 100px;
        }
    }
}

.nav-logo {
    width: 120px;
}

@include media-breakpoint-up(md) {

    .navbar {
        line-height: 1.8;
    }

    .nav-logo {
        width: 200px;
    }
}

@include media-breakpoint-up(lg) {


    .navbar:not(.scrolled-nav) {
        -webkit-box-shadow: 0px 3px 23px -15px rgba(0, 0, 0, 0.0);
        -moz-box-shadow: 0px 3px 23px -15px rgba(0, 0, 0, 0.0);
        box-shadow: 0px 3px 23px -15px rgba(0, 0, 0, 0);
    }


    .navbar {

        .nav-item {
            margin: 0 20px 0 0;
        }
    }
}

@include media-breakpoint-up(xl) {

    .nav-logo {
        width: 230px;
    }
}

.buffer-top {
    padding-bottom: 50px;
}

.dropdown-item.active,
.dropdown-item:active {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa;
}

// this adds the hover effect to the dropdown in desktop views\
// there are two things happening
// 1. underline navlink 2. expand dropdown
@include media-breakpoint-up(lg) {

    // initial state of the underline 
    .nav-link {
        .nav-link-span {
            &:after {
                content: "";
                height: 4px;
                background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, $brand-app, $brand-sec);
                display: block;
                transition: width .3s;
                width: 0;
            }
        }
    }

    // initial state of the dropdown
    .navbar .nav-item .dropdown-menu {
        display: none; 
    }

    // on hover we want the doprbdown & underlineto show
    .navbar .nav-item:hover,
    .navbar .nav-item.is-focused {

        .dropdown-menu {
            display: block;
        }

        .nav-link-span {
            &:after {
                width: 100%;
            }
        }
    }

    // there would be a little gap between dropbdown and hover element
    .navbar .nav-item .dropdown-menu{ margin-top:0; }

    // i
    .navbar .nav-item:hover .nav-link{}
    
}