
// logo/nav animation effect

body {
    padding-top: 80px;
}

@include media-breakpoint-up(sm) {
    body {
        padding-top: 100px;
    }

    .navbar {
        height: 104px;
        line-height: 100px;
    }
}

@include media-breakpoint-up(md) {
    body {
        padding-top: 120px;
    }

    .navbar {
        line-height: 120px;

    }
}

@include media-breakpoint-up(lg) {
    body {
        padding-top: 150px;
    }

    .navbar {
        height: 150px;
        line-height: 1.8;
        // line-height: 150px;
    }
}

.nav-logo {
    display: inline-block;
    -webkit-transition: all .5s;
    transition: all .5s;
}

.scrolled-nav .nav-logo {
    width: 120px;
}

.scrolled-nav .dropdown-menu {
    margin-top: 0;
}

.scrolled-nav {
    // height: 60px;
    line-height: 4;
    -webkit-box-shadow: 0px -14px 12px 7px rgb(0 0 0 / 36%);
    -moz-box-shadow: 0px -14px 12px 7px rgb(0 0 0 / 36%);
    box-shadow: 0px -14px 12px 7px rgb(0 0 0 / 36%);
}

@include media-breakpoint-up(sm) {
    .scrolled-nav {
        line-height: 80px;
    }
}

@include media-breakpoint-up(md) {
    .scrolled-nav {
        // line-height: 1.8;
        height: 84px;
    }
}

@include media-breakpoint-up(lg) {
    .scrolled-nav {
        line-height: 1.8;
        // height: 80px;
    }
}

.counter {
    width: 20px;
    height: 20px;
    background: black;
    color: #fff;
    position: fixed;
    top: 120px;
    right: 0;
}
