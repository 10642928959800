// bob-btn-gradient

.bob-btn-gradient {
    padding: 1rem;
    padding: 8px 30px;
    margin-bottom: 3px;
    color: #333;
    box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
    border: solid 2px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, $brand-app, $brand-sec);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #fff inset;
    cursor: pointer;
    font-weight: 400;
    font-family: $font-family-base;
    transition: linear;

    &:hover,
    &:active { 
        text-decoration: none;
        color: #333;
        background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, $brand-sec, $brand-sec);
    }

    &:focus { 
        text-decoration: none;
        color: #333;
        border: solid 2px $brand-sec;
        background-image: linear-gradient(rgb(255, 255, 255), rgb(255, 255, 255)), linear-gradient(101deg, #fff, #fff);
    }
}

.publications,
.newsitem {
    .bob-btn-sm {
        font-size: 14px;
        padding: .4rem 1rem;
        width: 150px;

        &:focus {
            outline: 0 none;
            border: solid 2px $brand-sec;
        }
    }
}